@tailwind base;
@tailwind components;
@tailwind utilities;

.demo {
    min-height: 20px;
    max-height: 2000px;
    overflow-x: hidden;
    overflow-y: scroll;
    border: 1px solid black;
  }